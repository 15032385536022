import React from 'react';
import { graphql } from 'gatsby';
import get from 'lodash/get';
import Layout, { LAYOUT_TYPES } from '../modules/layout';
import { CONTENT_TITLES, CONTENT_TYPES } from '../common/constants';
import Main from '../modules/main';
import { mapper } from '../common/utils';

const CAREER_MAPPER = {
  excerpt: ['summary', 'childMarkdownRemark', 'html'],
  title: ['title'],
  contact: ['contact'],
  email: ['email'],
  subHeading: ['subHeading'],
  document: ['content', 'json'],
  closingDate: ['closingDate'],
  location: ['location'],
  department: ['department'],
};

const getLayout = (career, contactBlock, url) => {
  const {
    excerpt,
    title,
    contact,
    email,
    subHeading,
    document,
    closingDate,
    department,
    location,
  } = mapper(career, CAREER_MAPPER);

  const getIntroText = () => {
    const closingDateStr =
      closingDate && `<br /><strong>Closing Date: </strong> ${closingDate}`;
    const locationStr =
      location && `<br /><strong>Location: </strong> ${location}`;
    const departmentStr =
      location && `<br /><strong>Department: </strong> ${department}`;
    return [
      ...(contact ? `<strong>Contact: </strong> ${contact} <br />` : null),
      `<strong>Email: </strong> <a style="text-decoration:none!important;" href="mailto:${email}?subject=${title}">${email}</a>`,
      closingDateStr,
      locationStr,
      departmentStr,
    ];
  };

  const summaryObj = {
    title: title,
    content: {
      childMarkdownRemark: {
        excerpt: excerpt,
      },
    },
    image: null,
  };

  let layout = [
    {
      __typename: LAYOUT_TYPES.HELMET,
      summary: summaryObj,
      url: url,
    },
    {
      __typename: LAYOUT_TYPES.BREADCRUMBS,
      items: [
        {
          to: `/${CONTENT_TYPES.CAREERS}`,
          label: CONTENT_TITLES.CAREERS,
        },
        {
          to: null,
          label: title,
        },
      ],
    },
    {
      __typename: LAYOUT_TYPES.HERO_TITLE,
      title: title,
      hasLogo: true,
      text: getIntroText().join(''),
      subtitle: [subHeading],
      colorScheme: 'green',
    },
    {
      __typename: LAYOUT_TYPES.CONTENT_BODY,
      scrollTabs: false,
      isRich: true,
      document: document,
      contentGrid: {
        order: 1,
        mb: [10],
        width: [9 / 12],
      },
      sidebarGrid: {
        order: 0,
        width: [3 / 12],
      },
      sidebar: [
        {
          __typename: LAYOUT_TYPES.SHARE,
          summary: summaryObj,
          url: url,
        },
      ],
    },
  ];

  if (contactBlock) {
    layout = [...layout, contactBlock];
  }

  return layout;
};

class CareerTemplate extends React.Component {
  render() {
    const url = this.props.location.href;
    const career = get(this.props, 'data.contentfulCareers');
    const contactBlock = get(this.props, 'pageContext.contactBlock');
    const layout = getLayout(career, contactBlock, url);

    return (
      <Main {...this.props} page={career}>
        <Layout layout={layout} />
      </Main>
    );
  }
}

export default CareerTemplate;

export const pageQuery = graphql`
  query CareersPostBySlug($slug: String!) {
    contentfulCareers(slug: { eq: $slug }) {
      contentful_id
      title
      slug
      closingDate(formatString: "DD MMMM YYYY")
      contact

      department
      email
      location
      subHeading
      content {
        json
      }
      summary {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`;
